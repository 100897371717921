import React from "react"
import { FaPuzzlePiece, FaDatabase, FaShippingFast, FaSortAmountUp} from "react-icons/fa"

const Benefits = () => {
    return(
        <div className="benefits">
        <div className="container">
        <div className="column">
        <div className="row">
                <div className="main-text"> The benefits. </div>
         </div>
         <div className="row">
         <div className="sub-text"> Minimize congestion, dynamically adapt the process to changes during the day, 
         and respond faster to customer and driver queries. Personalize the software to your process requirements with our 
         modular components. 
        </div>
        </div>
        <div className="row">
            <div className="column">
                <div className="title">Modular.</div>
                <div> <FaPuzzlePiece size={50} color={"#7FACD6"}/></div>
            </div>
            <div className="column">
                <div className="title">Dynamic.</div>
                <div> <FaShippingFast size={50} color={"#7FACD6"}/></div>
            </div>
            <div className="column">
                <div className="title">Always optimal.</div>
                <div> <FaSortAmountUp size={50} color={"#7FACD6"}/></div>
            </div>
            <div className="column">
                <div className="title">Data-driven.</div>
                <div> <FaDatabase size={50} color={"#7FACD6"}/></div>
            </div>
        </div>
        </div>
        </div>
        </div>
    )
}

export default Benefits