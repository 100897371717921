import React from "react"
import logo1 from "../images/ams_logo.png"
import logo2 from "../images/clique_logo.png"
import logo3 from "../images/logo_EIT.png"
import logo4 from "../images/startup_logo.png"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Hero = () => {
    const data = useStaticQuery(graphql`
    query {
      heroimage: file(relativePath: { eq: "hero-image.png" }) {
        childImageSharp {
          fluid(maxWidth: 1540) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    `)
    return (
        <div className = 'hero-image'>
            <div className = "container">
                <div className = "box-main">
                    <div className = "col1">
                        <div className = "main-text"> Waste management made easy.</div>
                        <p>
                        The smart waste platform connects clients, drivers and administration.
                        Through real-time communication, optimization and automation the full 
                        process from pickups to processing becomes more efficient.
                        </p>
                        <p>
                        Data and machine learning lie at the core of our software. 
                        </p>
                        <div className = "row1">
                            <a href="mailto:contact@skialabs.com"> <button className="button">Get in touch.</button> </a> 
                        </div>
                    </div>
                    <Img className='col2' fluid={data.heroimage.childImageSharp.fluid} />
                </div>
                <div className = "box-logo">
                    <div className = "row"> <h3>Partners.</h3></div>
                    <div className = "row">
                            <img src={logo1} alt="Ams"></img>
                            <img src={logo2} alt="Clique"></img> 
                            <img src={logo3} alt="EIT"></img>
                            <img src={logo4} alt="SIRA"></img>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero