import React from "react"
import "../styles/styles.scss"

import Header from "../components-en/header"
import Hero from "../components-en/hero"
import Planning from "../components-en/planning"
import Benefits from "../components-en/benefits"
import Features from "../components-en/features"
import Demo from "../components-en/demo"
import Footer from "../components-en/footer"

import {Helmet} from 'react-helmet'

const IndexPage = () => (
  <div>
    <Helmet>
        <html lang="en" />
        <title>Skialabs. Waste management made easy. </title>
        <description>Streamline waste management with the smart waste platform.</description>
      </Helmet>
    <Header />
    <Hero />
    <Planning />
    <Benefits />
    <Features />
    <Demo />
    <Footer />
  </div>
)

export default IndexPage
