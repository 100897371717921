import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Img from "gatsby-image"

const Planning = () => {
    const data = useStaticQuery(graphql`
    query {
      dynamische: file(relativePath: { eq: "Group 203.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      planning: file(relativePath: { eq: "client_data.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      inzicht: file(relativePath: { eq: "dispatch_com.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    `)

    return(
        <div className="planning">
        <div className="container">
        <div className="tabspart">
        <div className="maintext"> Efficient planning, based on data. </div>
            <Tabs>
                <TabList>
                    <Tab>Data-driven insights.</Tab>
                    <Tab>Real-time connection.</Tab>
                    <Tab>Dynamic routing.</Tab>
                </TabList>

                <TabPanel>
                  <div className = "text">
                    With the insights from our digital twin you will always know what waste streams to expect where and when.
                    Adapt the schedule and routes to future scenarios, analyze the quality of the collected waste, and 
                    communicate to the recycling companies what waste they can expect for optimal recycling.
                  </div> 
                  <div>
                    <Img imgStyle={{objectFit: 'contain'}} fluid={data.planning.childImageSharp.fluid} />
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className = "text">
                    The city dynamics are different every day. Locations that cannot be reached due to maintenance, a customer 
                    requesting a last-minute pickup, or the recycling company not being able to take in any waste. With 
                    our applications every player in the process is always up to date. 
                  </div> 
                  <div>
                    <Img imgStyle={{objectFit: 'contain'}} fluid={data.dynamische.childImageSharp.fluid} />
                  </div>
                </TabPanel>
                <TabPanel>
                    <div className = "text">
                      Capacity limits of the vehicle, travel restrictions in the city, an electric vehicle with a 
                      limited range or a location with a timewindow? No matter the complexity of your process, our 
                      state-of-the-art optimization algorithms ensure that the routes are always optimal and able 
                      to adapt to any changes during the day. 
                    </div>  
                    <div>
                      <Img imgStyle={{objectFit: 'contain'}} fluid={data.inzicht.childImageSharp.fluid} /> 
                    </div>
                        
                </TabPanel>
            </Tabs>
        </div>
        </div>
    </div>
    )
}

export default Planning